import React from "react";
import "./footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faTwitter,
  faInstagram,
  faLinkedin,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <footer className="footer">
      <div className="subscription">
        <h2 className="footer-title">Dataverse Solutions</h2>
        <p className="footer-text">
          Join us to stay up to date on job notifications
        </p>
        <input type="email" placeholder="Enter your email" />
        <button className="subscribe">Subscribe</button>
        <p className="footer-text">
          By subscribing you agree with our Privacy Policy and provide consent
          to receive updates from our company.
        </p>
      </div>
      {/* <div className="follow-us">
        <h2 className="follow-us-header">Follow us on</h2>
        <div className="social-container">
          <a href="https://facebook.com" className="social facebook">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
          <p>Facebook</p>
        </div>

        <div className="social-container">
          <a href="https://instagram.com" className="social instagram">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <p>Instagram</p>
        </div>
        <div className="social-container">
          <a href="https://twitter.com" className="social twitter">
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </a>
          <p>Twitter</p>
        </div>
        <div className="social-container">
          <a href="https://www.linkedin.com/company/dataverse-sol/" className="social linkedin">
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
          <p>LinkedIn</p>
        </div>
      </div> */}
    </footer>
  );
}

export default Footer;
