import React from 'react';
import { useParams } from 'react-router-dom';
import './platformPage.css';
import { useNavigate } from "react-router-dom";

const descriptions = {
  'TalentServices': (<>
    <p>At Dataverse Solutions, we specialize in connecting top-tier IT talent with leading organizations to drive innovation and success. Whether you need skilled professionals for a short-term project, permanent roles, or executive positions, our dedicated team of staffing experts is here to ensure you find the perfect fit.</p>

    <h3 className="platform-description">Our Services:</h3>
    <ul>
      <li><strong className="platform-description">Temporary & Contract Staffing:</strong> Quickly access specialized IT professionals for short-term projects or peak workloads.</li><br />
      <li><strong className="platform-description">Permanent Placement:</strong> Discover top talent for long-term roles with the skills and experience to make a lasting impact.</li><br />
      <li><strong className="platform-description">Executive Search:</strong> Find visionary leaders to guide your IT department and shape your company’s future.</li><br />
      <li><strong className="platform-description">Specialized Recruitment:</strong> From software developers and network engineers to cybersecurity experts and data analysts, we have the expertise to meet your unique needs.</li><br />
    </ul>

    <h3 className="platform-description">Why Choose Us?</h3>
    <ul>
      <li><strong className="platform-description">Industry Expertise:</strong> Our team has deep knowledge of the IT industry, allowing us to understand and meet your specific staffing needs.</li><br />
      <li><strong className="platform-description">Personalized Service:</strong> We take the time to understand your company culture and requirements to deliver candidates who are not only skilled but also a perfect fit for your team.</li><br />
      <li><strong className="platform-description">Proven Track Record:</strong> With a history of successful placements and satisfied clients, we’re committed to providing exceptional service and results.</li><br />
    </ul>
  </>
  ),
  'DataAnalytics': (
    <>
      <p>Unlock the Power of Your Data with Dataverse Solutions</p>

      <p>At Dataverse Solutions, we transform data into actionable insights that drive strategic decision-making and operational excellence. Our comprehensive data analytics solutions are designed to help businesses harness the full potential of their data, turning raw information into valuable intelligence.</p>

      <h3 className="platform-description">Our Data Analytics Services Include:</h3>
      <ul>
        <li><strong className="platform-description">Data Visualization:</strong> Create dynamic, easy-to-understand visualizations that provide clarity and insight, helping you make informed decisions quickly.</li><br />
        <li><strong className="platform-description">Predictive Analytics:</strong> Leverage advanced algorithms and statistical models to forecast future trends and behaviors, giving you a competitive edge.</li><br />
        <li><strong className="platform-description">Business Intelligence:</strong> Integrate and analyze data from various sources to uncover trends, patterns, and opportunities that drive business growth.</li><br />
        <li><strong className="platform-description">Data Warehousing:</strong> Build and maintain robust data warehouses that centralize and organize your data for efficient access and analysis.</li><br />
        <li><strong className="platform-description">Custom Analytics Solutions:</strong> Develop tailored analytics strategies and tools that align with your specific business goals and challenges.</li><br />
      </ul>

      <h3 className="platform-description">Why Partner with Us?</h3>
      <ul>
        <li><strong className="platform-description">Expertise and Innovation:</strong> Our team of data scientists and analysts employs the latest technologies and methodologies to deliver cutting-edge solutions.</li><br />
        <li><strong className="platform-description">Tailored Approach:</strong> We customize our services to meet your unique needs, ensuring that the insights we provide are relevant and impactful for your business.</li><br />
        <li><strong className="platform-description">Actionable Insights:</strong> We focus on providing insights that lead to tangible improvements and strategic advantages, helping you make data-driven decisions with confidence.</li><br />
        <li><strong className="platform-description">Proven Success:</strong> Our track record of successful projects and satisfied clients demonstrates our commitment to delivering high-quality results.</li><br />
      </ul>

      <p>Discover how Dataverse Solutions can help you unlock the full potential of your data. Contact us today to learn more about our data analytics services and how we can support your business in achieving its goals.</p>
    </>
  ),
  'BusinessServices': (
    <>
      <p>Streamline Your Operations with Dataverse Solutions – Comprehensive IT Business Services</p>

      <p>At Dataverse Solutions, we offer a suite of IT business services designed to optimize your operations, enhance productivity, and drive growth. Our expert team provides tailored solutions that address your unique challenges and help you leverage technology to achieve your business objectives.</p>

      <h3 className="platform-description">Our Business Services Include:</h3>
      <ul>
        <li><strong className="platform-description">IT Consulting:</strong> Partner with our experienced consultants to develop strategies that align technology with your business goals, ensuring you make informed decisions and invest wisely in IT.</li><br />
        <li><strong className="platform-description">Managed IT Services:</strong> Outsource your IT management to us and benefit from proactive maintenance, support, and oversight, allowing you to focus on your core business functions.</li><br />
        <li><strong className="platform-description">Cloud Solutions:</strong> Transition to the cloud with ease, utilizing scalable and cost-effective cloud services that enhance collaboration, flexibility, and data accessibility.</li><br />
        <li><strong className="platform-description">IT Support & Help Desk:</strong> Provide your team with reliable and responsive support to resolve technical issues quickly and minimize downtime.</li><br />
        <li><strong className="platform-description">System Integration:</strong> Seamlessly integrate disparate systems and applications to streamline workflows, improve data consistency, and boost operational efficiency.</li><br />
        <li><strong className="platform-description">Project Management:</strong> Ensure successful IT project execution with our expert project management services, from planning and implementation to monitoring and completion.</li><br />
      </ul>

      <h3 className="platform-description">Why Partner with Us?</h3>
      <ul>
        <li><strong className="platform-description">Expertise & Experience:</strong> Our team of skilled professionals brings extensive experience and industry knowledge to deliver high-quality IT solutions that drive business success.</li><br />
        <li><strong className="platform-description">Tailored Solutions:</strong> We understand that every business is unique. Our solutions are customized to meet your specific needs and objectives, ensuring maximum impact.</li><br />
        <li><strong className="platform-description">Customer-Centric Approach:</strong> We prioritize your needs and work closely with you to deliver services that enhance your operations and support your growth.</li><br />
        <li><strong className="platform-description">Proven Track Record:</strong> Our commitment to excellence and customer satisfaction is demonstrated by our history of successful projects and long-term client relationships.</li><br />
      </ul>

      <p>Discover how Dataverse Solutions can transform your business operations and drive success through our comprehensive IT business services. Contact us today to learn more about how we can support your IT needs and help you achieve your goals.</p>
    </>
  ),
  'CloudServices': (
    <>
      <p>Elevate Your Business with Scalable and Secure Cloud Solutions from Dataverse Solutions</p>

      <p>At Dataverse Solutions, we provide comprehensive cloud services designed to help your business achieve greater flexibility, scalability, and cost-efficiency. Our cloud solutions enable you to harness the power of cloud computing to enhance collaboration, optimize resources, and drive innovation across your organization.</p>

      <h3 className="platform-description">Our Cloud Services Include:</h3>
      <ul>
        <li><strong className="platform-description">Cloud Migration:</strong> Seamlessly transition your on-premise systems to the cloud with our expert migration services, minimizing downtime and ensuring data integrity.</li><br />
        <li><strong className="platform-description">Cloud Infrastructure Management:</strong> Optimize your cloud environment with our managed services, including monitoring, maintenance, and cost management.</li><br />
        <li><strong className="platform-description">Cloud Security:</strong> Protect your cloud assets with our advanced security solutions, including encryption, access control, and threat monitoring.</li><br />
        <li><strong className="platform-description">Cloud Consulting:</strong> Leverage our expertise to develop a cloud strategy that aligns with your business objectives, ensuring a successful and sustainable cloud adoption.</li><br />
        <li><strong className="platform-description">Hybrid & Multi-Cloud Solutions:</strong> Combine the best of public and private clouds with our hybrid and multi-cloud solutions, providing flexibility, redundancy, and optimized performance.</li><br />
        <li><strong className="platform-description">Disaster Recovery & Backup:</strong> Ensure business continuity with our robust disaster recovery and backup solutions, safeguarding your data and minimizing the impact of disruptions.</li><br />
      </ul>

      <h3 className="platform-description">Why Partner with Us?</h3>
      <ul>
        <li><strong className="platform-description">Comprehensive Expertise:</strong> Our team of cloud specialists brings extensive experience in designing, deploying, and managing cloud solutions that meet your specific needs.</li><br />
        <li><strong className="platform-description">Tailored Solutions:</strong> We customize our cloud services to fit your business requirements, ensuring maximum efficiency and cost savings.</li><br />
        <li><strong className="platform-description">Security First:</strong> We prioritize the security of your cloud environment, implementing the latest best practices and technologies to protect your data.</li><br />
        <li><strong className="platform-description">Proven Success:</strong> Our track record of successful cloud deployments and satisfied clients speaks to our commitment to excellence and customer satisfaction.</li><br />
      </ul>

      <p>Discover how Dataverse Solutions can help you leverage the power of cloud computing to drive business success. Contact us today to learn more about our cloud services and how we can support your journey to the cloud.</p>
    </>
  ),
  'BusinessIntelligence': (
    <>
      <p>Empower Your Decisions with Actionable Business Intelligence from Dataverse Solutions</p>

      <p>At Dataverse Solutions, we provide business intelligence solutions that transform raw data into meaningful insights, helping you make informed decisions that drive growth and success. Our BI services empower you to unlock the full potential of your data, providing you with the tools and knowledge to stay ahead in a competitive marketplace.</p>

      <h3 className="platform-description">Our Business Intelligence Services Include:</h3>
      <ul>
        <li><strong className="platform-description">Data Warehousing:</strong> Centralize your data with our robust data warehousing solutions, enabling seamless access and analysis of critical business information.</li><br />
        <li><strong className="platform-description">Reporting & Dashboards:</strong> Create customized reports and interactive dashboards that provide real-time insights into your key performance indicators (KPIs).</li><br />
        <li><strong className="platform-description">Data Mining & Analysis:</strong> Discover patterns, correlations, and trends within your data, allowing you to identify opportunities and make data-driven decisions.</li><br />
        <li><strong className="platform-description">Predictive Analytics:</strong> Leverage advanced analytics to forecast future trends, behaviors, and outcomes, giving you a strategic advantage in your industry.</li><br />
        <li><strong className="platform-description">Self-Service BI:</strong> Empower your team with user-friendly BI tools that allow them to explore data and generate insights independently, reducing reliance on IT.</li><br />
        <li><strong className="platform-description">BI Strategy Consulting:</strong> Develop a comprehensive BI strategy with our expert consultants, aligning your BI initiatives with your business goals for maximum impact.</li><br />
      </ul>

      <h3 className="platform-description">Why Partner with Us?</h3>
      <ul>
        <li><strong className="platform-description">Expertise & Innovation:</strong> Our team of BI professionals combines deep industry knowledge with the latest technologies to deliver cutting-edge solutions.</li><br />
        <li><strong className="platform-description">Tailored Solutions:</strong> We customize our BI services to meet your specific business needs, ensuring that you get the insights that matter most to you.</li><br />
        <li><strong className="platform-description">Actionable Insights:</strong> We focus on delivering insights that lead to tangible improvements and strategic advantages for your business.</li><br />
        <li><strong className="platform-description">Proven Results:</strong> Our history of successful BI implementations and satisfied clients demonstrates our commitment to quality and excellence.</li><br />
      </ul>

      <p>Unlock the full potential of your data with Dataverse Solutions. Contact us today to learn more about our business intelligence services and how we can help you make smarter, data-driven decisions.</p>
    </>
  ),

  'NetworkEngineering': (
    <>
      <p>Optimize Your Network Infrastructure with Expert Network Engineering from Dataverse Solutions</p>

      <p>At Dataverse Solutions, we provide comprehensive network engineering services designed to enhance the performance, reliability, and security of your network infrastructure. Our team of skilled network engineers works closely with you to design, implement, and maintain network solutions that meet your specific business needs and support your growth objectives.</p>

      <h3 className="platform-description">Our Network Engineering Services Include:</h3>
      <ul>
        <li><strong className="platform-description">Network Design & Architecture:</strong> Develop a robust and scalable network architecture that aligns with your business requirements, ensuring efficient and reliable connectivity.</li><br />
        <li><strong className="platform-description">Network Implementation:</strong> Execute the deployment of network infrastructure, including hardware, software, and configurations, to ensure seamless integration and optimal performance.</li><br />
        <li><strong className="platform-description">Network Optimization:</strong> Enhance network performance through tuning, monitoring, and troubleshooting, addressing any issues that impact speed, reliability, or efficiency.</li><br />
        <li><strong className="platform-description">Network Security:</strong> Implement security measures to protect your network from threats, including firewalls, intrusion detection systems, and secure access controls.</li><br />
        <li><strong className="platform-description">Network Maintenance & Support:</strong> Provide ongoing support and maintenance to ensure the continuous operation of your network, including regular updates, backups, and issue resolution.</li><br />
        <li><strong className="platform-description">Consulting & Strategy:</strong> Offer expert advice and strategic planning to align your network infrastructure with your business goals, ensuring scalability and adaptability to future needs.</li><br />
      </ul>

      <h3 className="platform-description">Why Partner with Us?</h3>
      <ul>
        <li><strong className="platform-description">Experienced Professionals:</strong> Our network engineers possess extensive experience and expertise in designing and managing complex network environments.</li><br />
        <li><strong className="platform-description">Customized Solutions:</strong> We tailor our network engineering services to your specific requirements, ensuring that our solutions address your unique challenges and goals.</li><br />
        <li><strong className="platform-description">Proactive Approach:</strong> We focus on proactive management and optimization to prevent potential issues and ensure your network operates at its best.</li><br />
        <li><strong className="platform-description">Commitment to Excellence:</strong> Our dedication to delivering high-quality network solutions is reflected in our track record of successful projects and satisfied clients.</li><br />
      </ul>

      <p>Transform your network infrastructure with Dataverse Solutions. Contact us today to learn more about our network engineering services and how we can help you build a resilient and high-performing network that supports your business objectives.</p>
    </>
  ),

  'CybersecuritySolutions': (
    <>
      <p>Protect Your Business with Advanced Cybersecurity Solutions from Dataverse Solutions</p>

      <p>At Dataverse Solutions, we understand that cybersecurity is a critical concern for businesses of all sizes. Our comprehensive cybersecurity solutions are designed to safeguard your organization against threats, ensuring that your data, systems, and operations remain secure and resilient in the face of evolving challenges.</p>

      <h3 className="platform-description">Our Cybersecurity Services Include:</h3>
      <ul>
        <li><strong className="platform-description">Threat Detection & Response:</strong> Identify and respond to potential security threats in real-time, minimizing the impact of cyber attacks on your business.</li><br />
        <li><strong className="platform-description">Network Security:</strong> Secure your network infrastructure with advanced firewalls, intrusion detection systems, and encryption technologies.</li><br />
        <li><strong className="platform-description">Endpoint Security:</strong> Protect your devices and endpoints from malware, ransomware, and other cyber threats with our comprehensive endpoint protection solutions.</li><br />
        <li><strong className="platform-description">Data Encryption & Protection:</strong> Safeguard sensitive data with robust encryption protocols, ensuring that your information remains secure both in transit and at rest.</li><br />
        <li><strong className="platform-description">Security Compliance & Audits:</strong> Ensure that your organization meets industry-specific security standards and regulations with our compliance and audit services.</li><br />
        <li><strong className="platform-description">Cybersecurity Training & Awareness:</strong> Empower your employees with the knowledge and skills to recognize and prevent cyber threats through our training programs.</li><br />
      </ul>

      <h3 className="platform-description">Why Partner with Us?</h3>
      <ul>
        <li><strong className="platform-description">Expertise & Experience:</strong> Our cybersecurity experts bring years of experience in protecting organizations from a wide range of cyber threats.</li><br />
        <li><strong className="platform-description">Proactive Approach:</strong> We focus on preventing security incidents before they occur, employing the latest technologies and methodologies to keep your business safe.</li><br />
        <li><strong className="platform-description">Tailored Solutions:</strong> We understand that every business has unique security needs. Our solutions are customized to address your specific challenges and vulnerabilities.</li><br />
        <li><strong className="platform-description">Proven Success:</strong> Our track record of successful cybersecurity implementations and satisfied clients underscores our commitment to excellence and security.</li><br />
      </ul>

      <p>Protect your business from cyber threats with Dataverse Solutions. Contact us today to learn more about our cybersecurity solutions and how we can help you secure your organization’s future.</p>
    </>
  ),

  'AboutUs': (
    <>
      <p>At Dataverse Solutions, we are committed to delivering cutting-edge solutions that drive growth, efficiency, and success for our clients. Our dedication to quality and customer satisfaction has established us as a leader in the industry. We focus on leveraging technology to tackle complex business challenges and create significant value for our clients.</p>

      <p>Our journey has been marked by innovation, integrity, and a relentless pursuit of excellence. Over the years, we have evolved into a trusted partner for businesses across various sectors, providing them with the tools and expertise needed to excel in a competitive market. Our team is passionate about pushing the boundaries of what’s possible and continuously improving to meet the ever-changing needs of our clients.</p>

      <h3 className="platform-description">Our Core Values:</h3>
      <ul>
        <li><strong className="platform-description">Innovation:</strong> We embrace new ideas and technologies to deliver forward-thinking solutions that drive success.</li><br/>
        <li><strong className="platform-description">Integrity:</strong> We conduct our business with honesty and transparency, building trust with our clients and stakeholders.</li><br/>
        <li><strong className="platform-description">Customer-Centricity:</strong> We prioritize our clients’ needs and work tirelessly to exceed their expectations.</li><br/>
      </ul>

      <p>Discover more about our mission, values, and the impact we are making in the world of business. Learn how our commitment to excellence can benefit your organization.</p>
    </>
  ),
  'OurTeam': (
    <>
      <p>Our team at Dataverse Solutions is a diverse group of professionals with expertise across a wide range of fields. From skilled engineers and data scientists to strategic business consultants and customer support specialists, each member contributes their unique skills and perspectives to our collective success.</p>

      <p>We believe that diversity and collaboration are the keys to driving innovation. Our work environment fosters creativity and teamwork, allowing us to tackle complex challenges and deliver exceptional results for our clients. Our team is dedicated to understanding client needs, providing tailored solutions, and ensuring that every project meets the highest standards of quality.</p>

      <h3 className="platform-description">Meet Our Experts:</h3>
      <ul>
        <li><strong className="platform-description">Engineers:</strong> Bringing technical expertise to design and implement cutting-edge solutions.</li><br/>
        <li><strong className="platform-description">Data Scientists:</strong> Analyzing and interpreting complex data to provide actionable insights.</li><br/>
        <li><strong className="platform-description">Business Consultants:</strong> Developing strategies to align technology with business goals.</li><br/>
        <li><strong className="platform-description">Support Specialists:</strong> Ensuring smooth operation and providing exceptional client support.</li><br/>
      </ul>

      <p>Learn more about the talented individuals who are driving our success and how their expertise contributes to our mission of delivering innovative solutions.</p>
    </>
  ),
  'Careers': (
    <>
      <p>At Dataverse Solutions, we are always on the lookout for talented individuals who are passionate about technology and eager to make a difference. We offer a dynamic and supportive work environment where you can work on exciting projects, learn from industry experts, and advance your career.</p>

      <p>Whether you are a seasoned professional or just starting out, we provide a range of opportunities for growth and development. We believe in investing in our employees and supporting their career aspirations through continuous learning and development.</p>

      <h3 className="platform-description">Why Join Us?</h3>
      <ul>
        <li><strong className="platform-description">Innovative Projects:</strong> Work on cutting-edge projects that challenge and inspire you.</li><br/>
        <li><strong className="platform-description">Professional Growth:</strong> Access to resources and training to help you achieve your career goals.</li><br/>
        <li><strong className="platform-description">Collaborative Environment:</strong> Be part of a team that values creativity, teamwork, and mutual support.</li><br/>
        <li><strong className="platform-description">Competitive Benefits:</strong> Enjoy a comprehensive benefits package and opportunities for advancement.</li><br/>
      </ul>

      <p>Explore our current job openings and see how you can become a part of our forward-thinking team. Join us and be a part of our journey to shape the future of technology.</p>
    </>
  )
};


const formatPlatformName = (name) => {
  return name.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
};

const PlatformPage = () => {
  const { platformName } = useParams();
  const navigate = useNavigate();
  const formattedPlatformName = formatPlatformName(platformName);
  const description = descriptions[platformName];

  const handlePageChange = () => {
    navigate("/apply");
  };

  console.log(platformName, 'platform');

  return (
    <div className="platform-container">
      <div className="platform-content">
        <h1 className="platform-title">{formattedPlatformName}</h1>
        <p className="platform-description">{description}</p>
        {platformName !== 'AboutUs' && platformName !== 'OurTeam' && (
          <button onClick={handlePageChange} className="apply-button">Apply Now</button>
        )}
      </div>
    </div>
  );
};

export default PlatformPage;
