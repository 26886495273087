import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/header/header';
import Title from './components/titlebar/titlebar';
import LandingPage from './components/landingPage/landingPage';
import ApplyNowPage from './components/applyNowPage/applyNowPage';
import Footer from './components/footer/footer';
import JobSearch from './components/jobSearch/jobSearch';
import NewsComponent from './components/newsComponent/newsComponent';
import Services from './components/services/services';
import PlatformPage from './components/platformPage/platformPage';
import Contact from './components/contact/Contact';

function App() {
  return (
    <BrowserRouter>
      {/* <Header /> */}
      <Title />

      <Routes> {/* Replace Switch with Routes */}
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/apply" element={<ApplyNowPage />} />
        <Route path="/jobsearch" element={<JobSearch />} />
        <Route path="/newscomponent" element={<NewsComponent />} />
        <Route path="/services" element={<Services />} /> {/* Route for Services */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/platforms/:platformName" element={<PlatformPage />} /> {/* Dynamic route for platform pages */}
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
