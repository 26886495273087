import React, { useState } from 'react';
import './newsComponent.css';

const NewsComponent = () => {
  const [selectedArticle, setSelectedArticle] = useState(null);

  const articlesData = [
    {
      title: "The Rise of Freshers in the Software Industry",
      description: "The software industry is experiencing a paradigm shift in its hiring practices...",
      fullArticle: (
        <>
          <hr />
          <h2>Introduction</h2>
          <p>
            The software industry is undergoing a transformative change in its approach to hiring. With the digital landscape evolving rapidly, there is a heightened demand for fresh talent capable of driving innovation and adapting to new technologies. This shift has led to a surge in initiatives aimed at recruiting and nurturing recent graduates with strong coding skills and problem-solving abilities.
          </p>
          <hr />
          <h2>A Shift in Hiring Practices</h2>
          <p>
            Traditionally, the software industry prioritized hiring experienced professionals. However, the increasing pace of technological advancements has prompted a change in hiring practices. Companies are now focusing on recruiting fresh graduates who bring fresh perspectives, innovative ideas, and a willingness to learn. This shift is driven by the industry's need for agility and adaptability in the face of constant change.
          </p>
          <hr />
          <h2>The Importance of Skills</h2>
          <p>
            Coding skills, problem-solving abilities, and adaptability are now at the forefront of hiring criteria for fresh graduates. These skills are crucial for navigating the complex and fast-paced world of software development. Companies are looking for candidates who can contribute meaningfully to projects, adapt to evolving technologies, and drive innovation.
          </p>
          <hr />
          <h2>Conclusion</h2>
          <p>
            As the software industry continues to evolve, the importance of fresh talent cannot be overstated. By focusing on hiring fresh graduates with strong skills and a willingness to learn, companies are positioning themselves for success in a rapidly changing digital landscape. This trend is likely to continue as the industry seeks to remain agile and innovative in the face of constant change.
          </p>
        </>
      ),
    },
    {
      title: "Navigating the Future: AI in Software Development",
      description: "AI tools are revolutionizing the way software is developed...",
      fullArticle: (
        <>  
          <hr />
          <h2>Introduction</h2>
          <p>
            Artificial intelligence (AI) is becoming a cornerstone in software development, enabling developers to create more efficient, reliable, and innovative solutions. From code generation to bug prediction, AI tools are transforming traditional development practices and paving the way for a new era of software engineering.
          </p>
          <hr />
          <h2>AI's Role in Software Development</h2>
          <p>
            AI-driven tools assist developers by automating repetitive tasks, optimizing code, and predicting potential issues. These advancements allow developers to focus on higher-level design and problem-solving, enhancing both productivity and the quality of software products.
          </p>
          <hr />
          <h2>Challenges and Ethical Considerations</h2>
          <p>
            While AI offers numerous benefits, it also raises challenges and ethical considerations. The reliance on AI tools can lead to a dependency that may diminish a developer's problem-solving skills. Additionally, ethical concerns around AI decision-making must be addressed to ensure fair and unbiased software solutions.
          </p>
          <hr />
          <h2>Conclusion</h2>
          <p>
            The integration of AI in software development is inevitable, bringing both opportunities and challenges. By embracing AI, developers can stay ahead of the curve, but they must also be mindful of the ethical implications and strive for responsible AI usage.
          </p>
        </>
      ),
    },
    {
      title: "Remote Work and Agile Methodologies in Software Development",
      description: "The rise of remote work has accelerated the adoption of agile practices in software teams...",
      fullArticle: (
        <>
          <hr />
          <h2>Introduction</h2>
          <p>
            The COVID-19 pandemic has permanently transformed the workplace, particularly in the software industry. Remote work has become a standard practice, prompting a widespread adoption of agile methodologies to maintain productivity and collaboration in a virtual environment.
          </p>
          <hr />
          <h2>Adapting to Remote Work</h2>
          <p>
            Agile methodologies, with their emphasis on flexibility and iterative progress, are well-suited to remote work settings. Teams can adapt to changes quickly, collaborate effectively, and maintain high levels of productivity despite physical distance.
          </p>
          <hr />
          <h2>Tools and Techniques for Remote Agile Teams</h2>
          <p>
            Tools like Slack, Zoom, and Jira have become essential for remote agile teams, facilitating communication, project management, and collaboration. These tools help teams stay connected, share updates, and track progress in real-time, ensuring smooth and efficient workflows.
          </p>
          <hr />
          <h2>Conclusion</h2>
          <p>
            As remote work continues to evolve, agile methodologies will remain a vital component of software development. By leveraging agile practices and digital tools, software teams can overcome the challenges of remote work and achieve their goals efficiently.
          </p>
        </>
      ),
    },
    {
      title: "The Importance of Cybersecurity in Modern Software Development",
      description: "Cybersecurity is becoming increasingly crucial in software development due to rising threats...",
      fullArticle: (
        <> 
          <hr />
          <h2>Introduction</h2>
          <p>
            In an era of escalating cyber threats, cybersecurity has become a fundamental aspect of software development. Modern software products must be designed with security in mind to protect sensitive data and maintain user trust.
          </p>
          <hr />
          <h2>Integrating Security into the Development Process</h2>
          <p>
            Developers are now integrating security measures throughout the software development lifecycle, adopting practices such as DevSecOps to ensure robust protection against potential vulnerabilities. This proactive approach helps mitigate risks and enhances the overall security posture of software products.
          </p>
          <hr />
          <h2>Training and Awareness</h2>
          <p>
            To keep up with the growing threat landscape, organizations are investing in cybersecurity training for their developers. By fostering a culture of security awareness, companies can equip their teams with the knowledge and skills needed to build secure software solutions.
          </p>
          <hr />
          <h2>Conclusion</h2>
          <p>
            As cyber threats continue to evolve, the importance of cybersecurity in software development cannot be overstated. By prioritizing security and investing in training, developers can create safer, more reliable software that meets the demands of today's digital world.
          </p>
        </>
      ),
    },
  ];
  

  const handleLearnMore = (article) => {
    setSelectedArticle(article);
  };

  const handleClose = () => {
    setSelectedArticle(null);
  };

  return (
    <div className="news-container">
      <div className="news-articles">
        {articlesData.map((article, index) => (
          <div key={index} className="news-item">
            <h2 className="card-header">{article.title}</h2>
            <p>{article.description}</p>
            <button className="apply-button" onClick={() => handleLearnMore(article)}>View</button>
          </div>
        ))}
      </div>
      {selectedArticle && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleClose}>×</span>
            <h2>{selectedArticle.title}</h2>
            {selectedArticle.fullArticle}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsComponent;
