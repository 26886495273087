import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import './contact.css';
import { useNavigate } from "react-router-dom";

const GmailIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 30 30"
    data-name="Layer 1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.58,19.1068l-12.69-8.0757A3,3,0,0,1,7.1109,5.97l9.31,5.9243L24.78,6.0428A3,3,0,0,1,28.22,10.9579Z"
      fill="#ea4435"
    />
    <path
      d="M25.5,5.5h4a0,0,0,0,1,0,0v18a3,3,0,0,1-3,3h0a3,3,0,0,1-3-3V7.5a2,2,0,0,1,2-2Z"
      fill="#00ac47"
      transform="translate(53.0001 32.0007) rotate(180)"
    />
    <path
      d="M29.4562,8.0656c-.0088-.06-.0081-.1213-.0206-.1812-.0192-.0918-.0549-.1766-.0823-.2652a2.9312,2.9312,0,0,0-.0958-.2993c-.02-.0475-.0508-.0892-.0735-.1354A2.9838,2.9838,0,0,0,28.9686,6.8c-.04-.0581-.09-.1076-.1342-.1626a3.0282,3.0282,0,0,0-.2455-.2849c-.0665-.0647-.1423-.1188-.2146-.1771a3.02,3.02,0,0,0-.24-.1857c-.0793-.0518-.1661-.0917-.25-.1359-.0884-.0461-.175-.0963-.267-.1331-.0889-.0358-.1837-.0586-.2766-.0859s-.1853-.06-.2807-.0777a3.0543,3.0543,0,0,0-.357-.036c-.0759-.0053-.1511-.0186-.2273-.018a2.9778,2.9778,0,0,0-.4219.0425c-.0563.0084-.113.0077-.1689.0193a33.211,33.211,0,0,0-.5645.178c-.0515.022-.0966.0547-.1465.0795A2.901,2.901,0,0,0,23.5,8.5v5.762l4.72-3.3043a2.8878,2.8878,0,0,0,1.2359-2.8923Z"
      fill="#ffba00"
    />
    <path
      d="M5.5,5.5h0a3,3,0,0,1,3,3v18a0,0,0,0,1,0,0h-4a2,2,0,0,1-2-2V8.5a3,3,0,0,1,3-3Z"
      fill="#4285f4"
    />
    <path
      d="M2.5439,8.0656c.0088-.06.0081-.1213.0206-.1812.0192-.0918.0549-.1766.0823-.2652A2.9312,2.9312,0,0,1,2.7426,7.32c.02-.0475.0508-.0892.0736-.1354A2.9719,2.9719,0,0,1,3.0316,6.8c.04-.0581.09-.1076.1342-.1626a3.0272,3.0272,0,0,1,.2454-.2849c.0665-.0647.1423-.1188.2147-.1771a3.0005,3.0005,0,0,1,.24-.1857c.0793-.0518.1661-.0917.25-.1359A2.9747,2.9747,0,0,1,4.3829,5.72c.089-.0358.1838-.0586.2766-.0859s.1853-.06.2807-.0777a3.0565,3.0565,0,0,1,.357-.036c.076-.0053.1511-.0186.2273-.018a2.9763,2.9763,0,0,1,.4219.0425c.0563.0084.113.0077.169.0193a2.9056,2.9056,0,0,1,.286.0888,2.9157,2.9157,0,0,1,.2785.0892c.0514.022.0965.0547.1465.0795a2.9745,2.9745,0,0,1,.3742.21A2.9943,2.9943,0,0,1,8.5,8.5v5.762L3.78,10.9579A2.8891,2.8891,0,0,1,2.5439,8.0656Z"
      fill="#c52528"
    />
  </svg>
);

const Contact = () => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [message, setMessage] = useState('');
  const handleGetSupportClick = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };

  const handleSubmit = () => {
    console.log('Submitted message:', message);
    setIsPopoverVisible(false);
    setMessage('');
  };

  return (
    <div className="contact-page">
      <h1 className="contact-header">Hi, how can we help?</h1>
      <p className="contact-description">
        We are here to assist you with any questions or concerns you may have.
        Please feel free to reach out to us.
      </p>
      <button className="get-support-button" onClick={handleGetSupportClick}>
        Get Support
      </button>

      {isPopoverVisible && (
        <>
        <div className="popover">
          <h2 className="popover-header">We're here to help</h2>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message here..."
            className="support-textarea"
          />
          
        </div>
        <button className="submit-button" onClick={handleSubmit}>
        Submit
      </button>
      </>
      )}

      <div className="follow-us">
        <h2 className="follow-header">Follow us on</h2>
        <div className="socialMedia-icons">
          <a href="https://facebook.com" className="socialMedia-icon facebook">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
          <a href="https://instagram.com" className="socialMedia-icon instagram">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <a href="https://twitter.com" className="socialMedia-icon twitter">
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </a>
          <a href="mailto:hr@dataversetechnologies.com" className="socialMedia-icon gmail">
            <GmailIcon />
          </a>
          <a href="https://linkedin.com" className="socialMedia-icon linkedin">
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
